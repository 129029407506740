
<template>
  <b-card>
    <Filters
      :isLoadingProfit="isLoadingProfit"
      :isDownloadingExcel="isDownloadingExcel"
      @look-per-filter="filteredSearch"
      :showMonthFilter="false"
    />
   <br>
    <TablePerformance
      v-if="!isLoadingProfit"
      :performanceData="performanceDataList"
      :tableColumns="tableColumns"
      :sortBy="sortBy"
      :isSortDirDesc="isSortDirDesc"
      :isEmpty="isEmpty"
      :isLoadingExcel="isDownloadingExcel"
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      fixed
      responsive
      small
      style="overflow:auto"
      @save-performance="savePerformance"
      @calculate-performance="calculatePerformance"
      @recalculate-performance="recalculatePerformance"
      @look-per-filter="filteredSearch"
      @change-per-page="changePerPage"
      @set-tab-index="setTabIndex"
      @export-performance="downloadExcel"
    />

     <div class="isCentered isSpinner" v-else>
      <center>
        <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
        Cargando distribución de rendimiento
      </center>
    </div>

    <PaginationTable
      :currentPage="currentPage"
      :totalRows="filteredDetailPerformance.length"
      :perPage="perPage"
      @change-page="changePage"
      v-if="filteredDetailPerformance.length > 10 && !isLoadingProfit"
    />
  </b-card>
</template>

<script>
import { formatDateOnly } from "@/helpers/helpers";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Filters from '@/modules/fivesClub/components/profit/costs/Filters'
import TablePerformance from '@/modules/fivesClub/components/profit/costs/TablePerformance'
import PaginationTable from '@/modules/fivesClub/components/profit/costs/PaginationTable'

export default {
  components:{
    TablePerformance,
    PaginationTable,
    Filters,
  },
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      perPageOptions: [50, 100, 150, 200, 250, 500, 1000],
      sortBy: 'name',
      isSortDirDesc: true,
      refListTable : null,
      statusFilter: null,
      isDownloadingExcel:false,
      tableColumns: [
        { key: "contractcode", label: "Contrato", class: "text-center" },
        { key: "ownercode", label: "Propietario", class: "text-center" },
        { key: "ownername", stickyColumn: true, label: "Nombre", class: "text-center" },
        { key: "housingnumber", label: "Número de vivienda", class: "text-center" },
        { key: "vigency", label: "Vigencia", class: "text-center" },
        { key: "housingpoints", label: "Puntos", class: "text-center" },
        { key: "january", label: "Enero", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "february", label: "Febrero", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "march", label: "Marzo", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "april", label: "Abril", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "may", label: "Mayo", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "june", label: "Junio", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "july", label: "Julio", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "august", label: "Agosto", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "september", label: "Septiembre", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "october", label: "Octubre", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "november", label: "Noviembre", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "december", label: "Diciembre", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "adjustment", label: "Bono", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
        { key: "total", label: "Total", class: "text-center", formatter: value => { return this.formatDecimal(value, true) } },
      ],
      isLoadingProfit: false,
      isEmpty:false,
    };
  },
  async mounted() {
    this.isEmpty=true
    this.setPerformanceAnual([])
  },
  computed: {
    ...mapState("fivesClubProfit", ["blockingDataAnualProfit", "rentalPoolFilter"]),
    ...mapGetters('fivesClubProfit',['filteredDetailPerformance']),
    performanceDataList(){
        if (this.filteredDetailPerformance?.length) return this.filteredDetailPerformance?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
    },
  },
  methods: {
  ...mapMutations('fivesClubProfit', ['setPerformanceAnual','setBlockingDataAnualProfit']),
  ...mapActions('fivesClubProfit', ['fetchProfitGeneral','saveProfitClose','calculatePerformanceAnual','recalculatePerformanceAnual','fetchExcelProfitCost',
                                    'savePerformanceAdjustment']),
    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale);
    },
    changePage(page){
      this.currentPage = page
    },
    changePerPage(perPage){
      this.perPage = perPage
    },
    setTabIndex(index){
      this.$emit('set-tab-index', index)
    },
    formatDecimal(value, includeSymbol = false) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue) && isFinite(numericValue)) {
        return this.toDecimal(numericValue, includeSymbol);
      } else {
        return value;
      }
    },

    toDecimal(number, includeSymbol) {
      if (typeof number !== 'number' || isNaN(number) || !isFinite(number)) {
        throw new Error('Invalid number');
      }

      const formattedValue = number.toLocaleString('es-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      const formattedWithSymbol = includeSymbol ? `$${formattedValue}` : formattedValue;
      return formattedWithSymbol;
    },  

    async filteredSearch(payload){
      this.isLoadingProfit = true
      payload.isAnual = 1;
      const performanceData = await this.fetchProfitGeneral( payload )
      performanceData.forEach(element => {
        element.showBtns = false
        element.valueOriginal = element.adjustment
      });
      this.setBlockingDataAnualProfit(payload)
      this.setPerformanceAnual(performanceData)
      this.isEmpty=false
      this.isLoadingProfit = false
    },
    async savePerformance(){
      const payload = this.blockingDataAnualProfit
      this.blockingDataAnualProfit.isAnual = 1
      const result = await this.saveProfitClose(payload)
      if (result) await this.filteredSearch(payload)
      this.isLoadingProfit = false
    },
    async calculatePerformance(){
      this.isLoadingProfit = true
      const payload = this.blockingDataAnualProfit
      const result= await this.calculatePerformanceAnual(payload)
      if (result) await this.filteredSearch(payload)
      this.isLoadingProfit = false
    },
    async recalculatePerformance(){
      this.isLoadingProfit = true
      const payload = this.blockingDataAnualProfit
      const result = await this.recalculatePerformanceAnual(payload)
      if (result) await this.filteredSearch(payload)
      this.isLoadingProfit = false
    },

     async downloadExcel() {
      this.isDownloadingExcel=true
      const {rentalPoolId} = this.blockingDataAnualProfit
      const rentalPool = this.rentalPoolFilter?.find(pools => pools.id == rentalPoolId)?.name || ''
      const filename = `${rentalPool}_Rendimiento_Anual_${this.blockingDataAnualProfit.year}`;
      this.blockingDataAnualProfit.isAnual = 1
      const payload = {...this.blockingDataAnualProfit, downExcel: true, filename }
      console.log(payload)
      await this.fetchExcelProfitCost(payload)
      this.isDownloadingExcel=false
    },  
  },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 11px;
  }
  .buttonInfo button {
    padding: 9px;
  }

  .buttonInfo {
    display: table-cell;
  }
}
</style>
